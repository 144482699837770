/* eslint-disable no-nested-ternary */
import { Section, Survey, SurveyReport, SurveyVariantRecord } from "@vaultinum/vaultinum-api";
import { CheckCircleIcon, CheckIcon, EditIcon, FlagIcon, Menu, OptionsIcon, Progress, getLangCode, useAuthContext, useLang } from "../../../../common";
import { BaseLang } from "../../../lang";
import { getQuestionCount, getSectionCompletionDetails } from "../../tools";
import { ScoreBadge } from "./ScoreBadge";

enum CompletionStatus {
    COMPLETED = "completed",
    IN_PROGRESS = "in-progress",
    PENDING = "pending"
}

function SurveySubMenuIcon({
    completionPercent,
    allQuestionAnswered,
    sectionScore,
    isPreambleSection
}: {
    completionPercent: number;
    allQuestionAnswered: boolean;
    sectionScore?: number;
    isPreambleSection?: boolean;
}): JSX.Element {
    if (isPreambleSection) {
        return (
            <span className="px-2">
                <FlagIcon />
            </span>
        );
    }
    if (sectionScore && allQuestionAnswered) {
        return <ScoreBadge score={sectionScore} size={40} />;
    }
    return allQuestionAnswered ? (
        <CheckCircleIcon color="green" />
    ) : (
        <span className="mr-1">
            <Progress percent={completionPercent} style={{ width: 20 }} trailColor="#ddd" showInfo={false} />
        </span>
    );
}

function getSectionState(subSectionCompletion: number): CompletionStatus {
    if (subSectionCompletion > 0) {
        if (subSectionCompletion === 100) {
            return CompletionStatus.COMPLETED;
        } else {
            return CompletionStatus.IN_PROGRESS;
        }
    }
    return CompletionStatus.PENDING;
}

export default function SurveySidebar({
    surveyVariantRecord,
    surveyVersion,
    surveySections,
    activeSection,
    setActiveSection,
    allVariantQuestionsAnswered,
    surveyLang,
    showScores,
    surveyReport
}: {
    surveyVariantRecord: SurveyVariantRecord;
    surveyVersion: Survey.Version;
    surveySections: Section[];
    activeSection: Section;
    setActiveSection: (section: Section) => void;
    surveyLang: Survey.Lang;
    allVariantQuestionsAnswered?: boolean;
    showScores?: boolean;
    surveyReport?: SurveyReport | null;
}): JSX.Element {
    const lang = useLang<BaseLang>();
    const { userProfile } = useAuthContext();
    const surveyAnsweredQuestions = getQuestionCount(surveyVersion, surveyVariantRecord, true);
    const surveyTotalQuestions = getQuestionCount(surveyVersion, surveyVariantRecord, false);
    const surveyProgress = (100 * surveyAnsweredQuestions) / surveyTotalQuestions;

    return (
        <Menu
            mode="inline"
            defaultOpenKeys={surveySections.map(({ id }) => id)}
            className="sections-sidebar h-full overflow-y-auto overflow-x-hidden"
            selectedKeys={[activeSection.id]}
        >
            <div className="p-2">
                {surveyReport ? (
                    <h4 className="m-4">
                        {lang.report.completed} ({surveyReport.creationDate.toLocaleDateString(getLangCode(userProfile))})
                    </h4>
                ) : (
                    <Progress percent={surveyProgress} format={() => `${surveyAnsweredQuestions} / ${surveyTotalQuestions}`} />
                )}
            </div>
            <Menu.Divider />
            {surveySections.map(section => {
                if (section.sections?.length) {
                    const { totalQuestions, completionPercent, allQuestionAnswered, sectionScore } = getSectionCompletionDetails(
                        section,
                        surveyVariantRecord,
                        !!showScores
                    );
                    if (!totalQuestions) {
                        return null;
                    }
                    return (
                        <Menu.SubMenu
                            key={section.id}
                            icon={
                                <SurveySubMenuIcon
                                    completionPercent={completionPercent}
                                    allQuestionAnswered={allQuestionAnswered}
                                    sectionScore={sectionScore}
                                    isPreambleSection={section.id === surveyVersion.preambleSectionId}
                                />
                            }
                            title={surveyLang.sections[section.id].title}
                            disabled={!allVariantQuestionsAnswered}
                        >
                            {section.sections?.map((subSection: Section) => {
                                const { totalQuestions: subSectionTotalQuestions, completionPercent: subSectionCompletion } = getSectionCompletionDetails(
                                    subSection,
                                    surveyVariantRecord,
                                    false
                                );
                                if (!subSectionTotalQuestions) {
                                    return null;
                                }
                                const state = getSectionState(subSectionCompletion);
                                return (
                                    <Menu.Item
                                        key={subSection.id}
                                        onClick={() => setActiveSection(subSection)}
                                        icon={
                                            <>
                                                {state === CompletionStatus.COMPLETED && <CheckIcon color="green" />}
                                                {state === CompletionStatus.IN_PROGRESS && <EditIcon color="grey" shade="light" />}
                                                {state === CompletionStatus.PENDING && <OptionsIcon color="grey" shade="light" />}
                                            </>
                                        }
                                        title={surveyLang.sections[subSection.id].title}
                                        disabled={!allVariantQuestionsAnswered}
                                    >
                                        {surveyLang.sections[subSection.id].title}
                                    </Menu.Item>
                                );
                            })}
                        </Menu.SubMenu>
                    );
                } else {
                    const { totalQuestions, completionPercent, allQuestionAnswered, sectionScore } = getSectionCompletionDetails(
                        section,
                        surveyVariantRecord,
                        !!showScores
                    );
                    if (!totalQuestions) {
                        return null;
                    }
                    return (
                        <Menu.Item
                            key={section.id}
                            onClick={() => setActiveSection(section)}
                            icon={
                                <SurveySubMenuIcon
                                    completionPercent={completionPercent}
                                    allQuestionAnswered={allQuestionAnswered}
                                    sectionScore={sectionScore}
                                    isPreambleSection={section.id === surveyVersion.preambleSectionId}
                                />
                            }
                            title={surveyLang.sections[section.id].title}
                            disabled={!allVariantQuestionsAnswered}
                        >
                            {surveyLang.sections[section.id].title}
                        </Menu.Item>
                    );
                }
            })}
        </Menu>
    );
}
