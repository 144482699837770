import {
    areAllVariantQuestionsAnswered,
    FullAudit,
    query,
    Question,
    QuestionAnswer,
    Section,
    StaffUser,
    Survey,
    SurveyReport,
    SurveyVariantRecord
} from "@vaultinum/vaultinum-api";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Buttons, Drawer, isNotNull, Layout, Layouts, SurveyIcon, useLang } from "../../../../common";
import { filterQuestions, QuestionWithKeys, sectionHasNotes } from "../../helpers";
import { EnrichedFullAudit, getQuestionCount, getQuestionFromId, getQuestionIndexes } from "../../tools";
import "./SurveyForm.css";
import SurveySection from "./SurveySection";
import SurveySidebar from "./SurveySidebar";

function getActiveSection(sections: Section[], activeSectionId: string): Section | null {
    for (const section of sections) {
        if (section.id === activeSectionId) {
            return section;
        }
        if (section.sections) {
            const activeSection = getActiveSection(section.sections, activeSectionId);
            if (activeSection) {
                return activeSection;
            }
        }
    }
    return null;
}

export default function SurveyForm({
    surveyVersion,
    surveyLang,
    activeSectionId,
    surveyVariantRecord,
    setActiveSection,
    currentAccountUserId,
    showSelectedOptionEvals,
    showScores,
    surveyReport,
    onCompletedText,
    onAnswer,
    onCompleted,
    scrollInto,
    fullAudit,
    staffUser,
    notes,
    showFilter
}: {
    surveyVersion: Survey.Version;
    surveyLang: Survey.Lang;
    activeSectionId: string;
    surveyVariantRecord: SurveyVariantRecord;
    setActiveSection: (section: Section) => void;
    currentAccountUserId?: string;
    showSelectedOptionEvals?: boolean;
    showScores?: boolean;
    surveyReport?: SurveyReport | null;
    onCompletedText?: string;
    onAnswer?: (answer: QuestionAnswer) => void;
    onCompleted?: () => void;
    scrollInto?: (nextQuestion: Question) => void;
    fullAudit?: EnrichedFullAudit;
    staffUser?: StaffUser;
    notes?: FullAudit.SurveyRecordNote[];
    showFilter?: boolean;
}) {
    const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
    const location = useLocation();
    const lang = useLang();

    const questionIndexes = getQuestionIndexes(surveyVersion);
    const surveyCompleted = getQuestionCount(surveyVersion, surveyVariantRecord, true) === getQuestionCount(surveyVersion, surveyVariantRecord, false);
    const questions = query(surveyVersion).getOrderedQuestions(surveyVariantRecord);
    const urlQueryParams = new URLSearchParams(location.search);
    const questionId = urlQueryParams.get("questionId");

    const allVariantQuestionsAnswered = areAllVariantQuestionsAnswered(surveyVersion, surveyVariantRecord);

    useEffect(() => {
        if (questionId && scrollInto) {
            const question = getQuestionFromId(questions, questionId);
            if (question) {
                scrollInto(question);
            }
        }
        return () => {};
    }, []);

    const changeSection = (section: Section) => {
        setActiveSection(section);
        if (isDrawerVisible) {
            setIsDrawerVisible(false);
        }
    };

    const jumpToNextQuestion = () => {
        if (scrollInto) {
            let foundCurrentQuestion = questionId === null;
            // Iterate over duplicated questions, to ensure moving to next unanswered question at the start if all "last" questions are done
            for (const question of [...questions, ...questions]) {
                if (!query(question).isAnswered(surveyVariantRecord) && foundCurrentQuestion) {
                    scrollInto(question);
                    break;
                }
                if (question.id === questionId) {
                    foundCurrentQuestion = true;
                }
            }
        }
    };

    function getQuestionTextById(id: string): string {
        return surveyLang.questions[id]?.text;
    }

    function getMatchingItems(list: Section[], searchText: string): Question[] {
        return list.flatMap(section => [
            ...(section.questions?.filter(question => questions.includes(question)) || []).flatMap(question => {
                if (getQuestionTextById(question.id).toLowerCase().includes(searchText.toLowerCase())) {
                    return [question];
                }
                return [];
            }),
            ...(section.questions || []).flatMap(question => getMatchingItems(question.options, searchText)),
            ...(section.sections || []).flatMap((subSection: Section) => getMatchingItems([subSection], searchText))
        ]);
    }

    const surveySidebar = (sections: Section[]) => (
        <SurveySidebar
            surveyVariantRecord={surveyVariantRecord}
            surveyVersion={surveyVersion}
            surveySections={sections}
            activeSection={getActiveSection(sections, activeSectionId) ?? sections[0]}
            setActiveSection={changeSection}
            allVariantQuestionsAnswered={allVariantQuestionsAnswered}
            surveyLang={surveyLang}
            surveyReport={surveyReport}
            showScores={showScores}
        />
    );

    const closeDrawer = () => {
        setIsDrawerVisible(false);
    };

    function onFilter(item: Section, selectedOptions: string[]): Section | null {
        const showQuestionsWithNotesOnly = selectedOptions.includes(QuestionWithKeys.Notes);
        const showQuestionWithRecommendations = selectedOptions.includes(QuestionWithKeys.Recommendations);
        const sectionHasRecommendations = query(item).hasRecommendations(surveyVariantRecord);
        if (
            (showQuestionsWithNotesOnly && sectionHasNotes(item, surveyVersion, notes)) ||
            (showQuestionWithRecommendations && sectionHasRecommendations) ||
            (showQuestionsWithNotesOnly && showQuestionWithRecommendations && (sectionHasNotes(item, surveyVersion, notes) || sectionHasRecommendations))
        ) {
            const filteredQuestions = filterQuestions(
                item.questions ?? [],
                notes,
                surveyVariantRecord,
                showQuestionsWithNotesOnly,
                showQuestionWithRecommendations
            );
            const filteredSubSections = item.sections?.map(subSection => onFilter(subSection, selectedOptions)).filter(isNotNull);
            return { ...item, questions: filteredQuestions, sections: filteredSubSections ?? [] };
        }
        return null;
    }

    return (
        <div className="survey-form">
            <Layouts.List
                list={surveyVersion.sections}
                render={list => (
                    <Layout className="relative h-80-vh border border-grey-300 bg-white">
                        <Layout.Sider
                            breakpoint="md"
                            collapsedWidth="0"
                            onCollapse={(_collapsed, type) => type === "clickTrigger" && !isDrawerVisible && setIsDrawerVisible(true)}
                            zeroWidthTriggerStyle={{
                                top: 0,
                                lineHeight: "2rem",
                                height: "2.4rem"
                            }}
                            className="bg-inherit"
                            width="16rem"
                        >
                            {surveySidebar(list)}
                        </Layout.Sider>
                        {/* Use Drawer for mobile */}
                        <Drawer
                            header={{
                                Icon: SurveyIcon,
                                title: surveyLang.survey.title
                            }}
                            children={surveySidebar(list)}
                            onClose={closeDrawer}
                            isVisible={isDrawerVisible}
                        />
                        <Layout.Content className="h-full overflow-y-scroll">
                            <SurveySection
                                surveyLang={surveyLang}
                                section={getActiveSection(list, activeSectionId) ?? list[0]}
                                surveySections={list}
                                onAnswer={onAnswer}
                                record={surveyVariantRecord}
                                questionIndexes={questionIndexes}
                                showSelectedOptionEvals={showSelectedOptionEvals}
                                location={location}
                                fullAudit={fullAudit}
                                currentAccountUserId={currentAccountUserId}
                                staffUser={staffUser}
                                notes={notes}
                                showRecommendations={showFilter}
                            />
                        </Layout.Content>
                        {!surveyCompleted && (
                            <div className="absolute bottom-5 right-5">
                                <Buttons.Next onClick={jumpToNextQuestion} isDisabled={!allVariantQuestionsAnswered} isLoading={false} />
                            </div>
                        )}
                        {surveyCompleted && onCompleted && onCompletedText && (
                            <div className="absolute bottom-5 right-5">
                                <Button onClick={onCompleted} isDisabled={!allVariantQuestionsAnswered} isLoading={false} children={onCompletedText} />
                            </div>
                        )}
                    </Layout>
                )}
                searchNavigation={{
                    getMatchingItems,
                    onCurrentMatchingItemChange: (item?: Question) => {
                        if (item) {
                            scrollInto?.(item);
                        }
                    }
                }}
                {...(showFilter
                    ? {
                          filters: [
                              {
                                  key: "questionWith",
                                  label: lang.survey.filterQuestionWith,
                                  options: [
                                      ...(notes?.length ? [{ label: lang.survey.questionWithNotes, value: QuestionWithKeys.Notes }] : []),
                                      { label: lang.survey.questionWithRecommendations, value: QuestionWithKeys.Recommendations }
                                  ],
                                  onFilter
                              }
                          ]
                      }
                    : {})}
            />
        </div>
    );
}
